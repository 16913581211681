import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/all-activities',
    name: 'all-activities',
    component: () => import(/* webpackChunkName: "allActivities" */ '../views/activity/all.vue'),
    meta: {
      title: '全部活动',
    },
  },
  {
    path: '/my-activities',
    name: 'my-activities',
    component: () => import(/* webpackChunkName: "myActivities" */ '../views/activity/my.vue'),
    meta: {
      title: '我的活动',
    },
  },
  {
    path: '/activity-details',
    name: 'activity-details',
    component: () => import(/* webpackChunkName: "activityDetails" */ '../views/activity/details.vue'),
    meta: {
      title: '活动详情',
    },
  },
  {
    path: '/activity-result',
    name: 'activity-result',
    component: () => import(/* webpackChunkName: "activityResult" */ '../views/activity/result.vue'),
    meta: {
      title: '结果',
    },
  },
  {
    path: '/activity-reward',
    name: 'activity-reward',
    component: () => import(/* webpackChunkName: "activityReward" */ '../views/activity/reward.vue'),
    meta: {
      title: '奖励页',
    },
  },
  {
    path: '/perfect-musicain',
    name: 'perfect-musicain',
    component: () => import(/* webpackChunkName: "perfectMusicain" */ '../views/perfect-info/musicain.vue'),
    meta: {
      title: '音乐人信息补全',
    },
  },
  {
    path: '/perfect-mechanism',
    name: 'perfect-mechanism',
    component: () => import(/* webpackChunkName: "perfectMechanism" */ '../views/perfect-info/mechanism.vue'),
    meta: {
      title: '厂牌机构信息补全',
    },
  },
]

export default routes
