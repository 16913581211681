import { App } from 'vue'
import {
  Cell,
  CellGroup,
  Switch,
  Icon,
  Checkbox,
  Field,
  NumberKeyboard,
  CountDown,
  Loading,
  Dialog,
  Overlay,
  Tab,
  Tabs,
  Skeleton,
  CheckboxGroup,
} from 'vant'

export default {
  install(app: App): void {
    app
      .use(Cell)
      .use(CellGroup)
      .use(Switch)
      .use(Icon)
      .use(Checkbox)
      .use(Field)
      .use(NumberKeyboard)
      .use(CountDown)
      .use(Loading)
      .use(Overlay)
      .use(Dialog)
      .use(Tab)
      .use(Tabs)
      .use(Skeleton)
      .use(CheckboxGroup)
  },
}
