// 设置localStorage
export const setStorage = (key: string, val: string): void => localStorage.setItem(key, val)

// 获取localStorage
export const getStorage = (key: string): string | null => localStorage.getItem(key)

// 移除localStorage
export const removeStorage = (key: string): void => localStorage.removeItem(key)

// 设置sessionStorage
// export const setSessionStorage = (key: string, val: string): void => localStorage.setItem(key, val)
