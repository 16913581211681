export const UNIT_PERCENTAGE = 'percentage' // 单位 -> 百分比

export const UNIT_SOLA = 'sola' // 单位 -> 张

export const IDENTITY_MUSICIAN = 'musician' // 身份 -> 音乐人

export const IDENTITY_TALENT = 'talent' // 身份 -> 网红达人

export const status: { [key: string]: string } = {
  0: '未开始',
  1: '进行中', // 活动进行中，报名未截至
  2: '进行中', // 活动进行中，报名已截至
  3: '已结束',
}

export const songStatus: { [key: string]: string } = {
  1: '已通过',
  2: '感谢参与',
  3: '已报名',
}
