import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue'),
    meta: {
      title: '钱包首页',
    },
  },
  {
    path: '/details',
    name: 'details',
    component: () => import(/* webpackChunkName: "details" */ '../views/details/index.vue'),
    meta: {
      title: '版权详情',
    },
  },
  {
    path: '/assets',
    name: 'assets',
    component: () => import(/* webpackChunkName: "assets-list" */ '../views/transfer/AssetsList.vue'),
    meta: {
      title: '资产列表',
    },
  },
  {
    path: '/transfer',
    name: 'transfer',
    component: () => import(/* webpackChunkName: "about" */ '../views/transfer/index.vue'),
    meta: {
      title: '转账',
    },
  },
  {
    path: '/receive',
    name: 'receive',
    component: () => import(/* webpackChunkName: "receive" */ '../views/transfer/Receive.vue'),
    meta: {
      title: '转账领取',
    },
  },
  {
    path: '/detailed',
    name: 'detailed',
    component: () => import(/* webpackChunkName: "detailed" */ '../views/detailed/index.vue'),
    meta: {
      title: '明细',
    },
  },
]

export default routes
