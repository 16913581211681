// import { h } from 'vue'
import { RouteRecordRaw } from 'vue-router'
import wallet from './wallet'
import musician from './musician'
import Layout from '@/views/layout.vue'

// eslint-disable-next-line
// const Layout = () => h('div', 'layout')
// Layout.displayName = 'Layout'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '/',
    component: Layout,
    // redirect: '/wallet',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/transition',
    name: 'transition',
    component: () => import(/* webpackChunkName: "transition" */ '@/views/login/Transition.vue'),
    meta: {
      title: '回调过渡页',
    },
  },
  {
    path: '/bind',
    name: 'bind',
    component: () => import(/* webpackChunkName: "bind" */ '@/views/bind/index.vue'),
    meta: {
      title: '绑定手机号',
    },
  },
  {
    path: '/my',
    name: 'my',
    component: () => import(/* webpackChunkName: "my" */ '../views/my/index.vue'),
    meta: {
      title: '我的',
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/terms/index.vue'),
    meta: {
      title: '用户协议',
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy/index.vue'),
    meta: {
      title: '隐私政策',
    },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/help/index.vue'),
    meta: {
      title: '帮助',
    },
  },
  {
    path: '/identity',
    name: 'identity',
    component: () => import(/* webpackChunkName: "identity" */ '../views/identity/index.vue'),
    meta: {
      title: '身份选择',
    },
  },
  {
    path: '/debugger',
    name: 'debugger',
    component: () => import(/* webpackChunkName: "help" */ '../views/debugger/index.vue'),
    meta: {
      title: '测试',
    },
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "test" */ '../views/test.vue'),
    meta: {
      title: '测试',
    },
  },
  ...wallet,
  ...musician,
]

export default routes
