import { createStore } from 'vuex'
import { setStorage, getStorage } from '@/plugins/storage'
import { UNIT_PERCENTAGE, IDENTITY_MUSICIAN } from '@/config/const'

export default createStore({
  state: {
    unit: getStorage('wallet_unit') || UNIT_PERCENTAGE,
    identity: getStorage('wallet_identity') || IDENTITY_MUSICIAN,
    perfect: false,
    inform: false,
  },
  mutations: {
    // 切换单位类型(%/张)
    CHANGE_UNIT_TYPE: (state, payload) => {
      state.unit = payload
      setStorage('wallet_unit', payload)
    },
    // 切换身份类型(音乐人/网红达人)
    CHANGE_IDENTITY_TYPE: (state, payload) => {
      setStorage('wallet_identity', payload)
      state.identity = payload
    },
    // 设置补全信息弹窗
    CHANGE_PERFECT_TYPE: (state, payload) => {
      state.perfect = payload
    },
    // 设置提醒前往PC端报名弹窗
    CHANGE_INFORM_TYPE: (state, payload) => {
      state.inform = payload
    },
  },
  actions: {},
  modules: {},
})
